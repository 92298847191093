import React from "react";
import loadable from "@loadable/component";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
import { LandingPageTestimonials } from "../../data/restaurant-data";
import { ReserveLPTemplate } from "../../data/landing/reserve-lp-data";
// import LpLeadForm from "../../components/ConversionPath/LpLeadForm";
// import Image from "../../components/image";
// import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
// import PartnerLogos from "../../components/PartnerLogos/PartnerLogos";
// import Badges from "../../components/ConversionPath/Badges";
// import UspFeatures from "../../components/ConversionPath/UspFeatures";

import ogImage from "../../images/global_assets/og-image.png";

const LpLeadForm = loadable(() =>
  import("../../components/ConversionPath/LpLeadForm")
);
const Image = loadable(() => import("../../components/image"));
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../../components/PartnerLogos/PartnerLogos")
);
const Badges = loadable(() => import("../../components/ConversionPath/Badges"));
const UspFeatures = loadable(() =>
  import("../../components/ConversionPath/UspFeatures")
);

export default function RestaurantLP() {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn Reserve Demo | Point-of-Sale"
        description="Run and grow your business more efficiently than ever with a powerful reservation and table management"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demo__content-wrapper">
          <div className="header-img">
            <Image
              imageName="reserve-lp.png"
              style={{ marginTop: 20, maxWidth: "90%" }}
            />
          </div>
          <div className="form-wrapper is-landing">
            <LpLeadForm
              header="The restaurant reservation platform that works for you"
              subHeader="Book more reservations, serve more guests, and increase profits with the all-in-one reservation, waitlisting, and table-management platform."
              //   phoneNumber="tel:+18882246506"
              //   phoneText="(888) 224-6506"
              leadType="lead-gen"
              formId="63200368-6800-40e8-b199-1104d5b44950"
              thankYouPath="/lp/thank-you"
            />
          </div>
        </div>
        <Badges />
        <section>
          <UspFeatures data={ReserveLPTemplate} />
        </section>
        <section style={{ marginBottom: 60 }}>
          <TestmonialReviews sectionData={LandingPageTestimonials} />
          <PartnerLogos styles={{ marginBottom: 40 }} />
        </section>
      </section>
    </LpLayout>
  );
}
