/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const ReserveLpSuccess = {
  title: "success stories",
  subTitle: "Our merchant",
};

export const ReserveLPHero = {
  title: "The restaurant reservation platform that works for you",
  subtext:
    "Book more reservations, serve more guests, and increase profits with the all-in-one reservation, waitlisting, and table-management platform.",
  heroImg: "reserve-lp.png",
  imageClassName: "fixed-height-600",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-reserve",
  },
  fatTitle: "Reserve",
  fatTitleClass: "font-large",
};

export const ReserveLPWhiteGlove = {
  mainTitle: "Personalized support whenever you need it",
  title: "White-glove service & support",
  content:
    "Our team will work closely with you to configure your digital reservation platform in as little as 48 hours and then train your staff to get the most from it. Once you’re up and running, we’ll continue to be there for you with around-the-clock technical local support, included at no extra cost.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-reserve",
  },
};

export const ReserveLPLargeFeatures = {
  sectionTitle: "Restaurant tech with a human touch",
  featureBlocks: [
    {
      blockTitle: "More guests. Less stress. No per-cover fees",
      blockImg: "reserve-lp-1.png",
      blockSubTitle: "",
      blockList: [
        "Online reservations",
        "Reserve with Google",
        "Digital waitlisting",
        "Automated SMS confirmations",
        "Two-way table-ready messaging",
        "Guest text surveys",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-reserve",
      },
    },
    {
      blockTitle: "The perfect front-of-house solution to boost efficiency",
      blockSubTitle: "",
      blockImg: "reserve-lp-2.png",
      blockList: [
        "Host App for iPad",
        "Server & table management",
        "Web portal access, including for multiple location",
        "Contact tracing for COVID-19 certification",
        "Curbside pickup notifications",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-reserve",
      },
    },
    {
      blockTitle: "Powerful guest data & reporting",
      blockSubTitle: "",
      blockImg: "reserve-lp-3.png",
      blockList: [
        "Collect and own guest data with every reservation",
        "Know where your guests are coming from",
        "Drive repeat visits with targeted marketing campaigns",
        "Boost efficiency with optional POS integration",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-reserve",
      },
    },
  ],
};

export const ReserveLPTestimonials = {
  title: "What other resaturant owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes. I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const ReserveLPCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-reserve",
  },
};

export const ReserveLPTemplate = [
  {
    content: "Reservations & waitlisting through your website",
    desc: "Keep your tables full and reduce walk-aways by letting your guests book directly from you.",
    img: "Online_reservation.png",
  },
  {
    content: "Reserve with Google listing",
    desc: "Attract more guests by enabling them to reserve a table or add themselves to your guest list right from a web search.",
    img: "Google_listings.png",
  },
  {
    content: "Host application for iPad",
    desc: "Boost efficiency of your front-of-house by easily managing reservations, waitlists, and walk-ins.",
    img: "Host_application.png",
  },
  {
    content: "Server & table management",
    desc: "Streamline operations, reduce labor costs, and turn tables faster with powerful front-of-house tools.",
    img: "Service_management.png",
  },
  {
    content: "Web portal access",
    desc: "Easily manage your front-of-house, wherever you are, including across multiple location.",
    img: "Online_reservation.png",
  },
  {
    content: "Customer analytics",
    desc: "Know who your guests are and where they’re coming from, so you can drive repeat visits.",
    img: "reporting.png",
  },
];
